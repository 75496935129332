// Imported into application.js


/*
Why do the following methods have a setTimeout?
The sidebar relies on a full screen element to darken the screen by
overlaying a dark, transparent layer. This overlay blocks interaction.
However, if we hide the overlay simply by making it invisible (css: 'visibility')
or transparant (css: 'opacity') it continues to block page interaction.

Instead, when we need to hide the overlay we must set display: none. This causes
a secondary issue with animation. Changing the 'display' property does not
support transitions (ie. it always transitions instantly) and will prevent other
concurrent transitions from animating whether we are showing or hiding the element.

To get around this, when showing the overlay, we remove the display: none attribute
first and only (infintesimally) after do we apply the new styles to the elements
that need to transition. When hiding, we wait until all transitions have finished
and only then do we set display: none to allow page interaction.
*/

window.openMobileNav = () => {
  // Display all elements first, then start animated transitions with setTimeout
  toggleClasses(byId('mobile-menu-container'), ['hidden']);

  setTimeout(function(){
    toggleClasses(byId('mobile-menu'), ['-translate-x-full', 'translate-x-0']);
    toggleClasses(byId('mobile-menu-overlay'), ['opacity-100', 'opacity-0']);
    toggleAria(byId('mobile-menu-overlay'), 'hidden', 'true', 'false');
    toggleClasses(byId('mobile-menu-close'), ['opacity-100', 'opacity-0']);
    toggleAria(byId('mobile-menu-close-icon'), 'hidden', 'true', 'false');
  }, 0)
}

window.closeMobileNav = () => {
  // Transition first, then hide interaction-blocking overlay after transitions

  toggleClasses(byId('mobile-menu'), ['-translate-x-full', 'translate-x-0']);
  toggleClasses(byId('mobile-menu-overlay'), ['opacity-100', 'opacity-0']);
  toggleAria(byId('mobile-menu-overlay'), 'hidden', 'true', 'false');
  toggleClasses(byId('mobile-menu-close'), ['opacity-100', 'opacity-0']);
  toggleAria(byId('mobile-menu-close-icon'), 'hidden', 'true', 'false');

  setTimeout(
    function() {toggleClasses(byId('mobile-menu-container'), ['hidden'])},
    300
  );
}