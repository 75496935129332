// ------ Global JS Utility Methods ------ //
// Consider factoring these into a new file if there are more than a few.

// Just an alias to make selection by ID less verbose
window.byId = function(id) {
  return document.getElementById(id);
}

window.setById = function(element_id, prop_name, value){
  byId(element_id)[prop_name] = value;
}

window.setByEl = function(element, prop_name, value){
  element[prop_name] = value;
}

window.getElProp = function(element_id, prop_name){
  return byId(element_id)[prop_name]
}

window.setElProp = function(element_id, prop_name, prop_value){
  byId(element_id)[prop_name] = prop_value
}


window.byIds = function(ids) {
  return ids.map(id => document.getElementById(id));
}

window.byCls = function(cls) {
  return document.getElementsByClassName(cls);
}

// Javascript is a silly language
window.isIterable = function(value) {
  return Symbol.iterator in Object(value);
}

// Will add class if not already present
window.toggleClasses = function(els, classes) {
  // Allow method to be used with one element or several
  if (!isIterable(els)) {
    els = [els]
  }

  // Different for syntax is because HTMLCollections don't iterate properly
  // with forEach, for secret reasons
  for(let el of els) {
    classes.forEach((cls) => {
      if (el.classList.contains(cls)) {
        el.classList.remove(cls); 
      } else {
        el.classList.add(cls);  
      }
    })
  };
}

window.setElClasses = function(el, classes, to=true) {
  classes.forEach((cls) => {
    if (el.classList.contains(cls) && to === false) {
      el.classList.remove(cls); 
    } else if (to === true) {
      el.classList.add(cls);  
    }
  });
}

// Will add aria attribute if not already present
window.toggleAria = function(el, ariaSuffix, activeVal, inactiveVal) {
  const attrName = "aria-" + ariaSuffix;
  
  if (el.getAttribute(attrName) == activeVal) {
    el.setAttribute(attrName, inactiveVal);
  } else {
    el.setAttribute(attrName, activeVal);
  }
}

// From https://stackoverflow.com/questions/143815/
// determine-if-an-html-elements-content-overflows
//
// Detects horizontal or vertical overflow of the element.
window.isOverflowing = function(el) {
  // We temporarily change the overflow style to hidden in order
  // to detect overflows, if the style is not already hidden
  const curOverflow = el.style.overflow;
  if ( !curOverflow || curOverflow === "visible" )
    el.style.overflow = "hidden";

  // Check overflow
  const isOverflowing = (
    el.clientWidth < el.scrollWidth ||
    el.clientHeight < el.scrollHeight
  )

  // Reset overflow to original setting
  el.style.overflow = curOverflow;

  return isOverflowing;
}

/*
Moves content that overflows one parent element into another parent
element. Returns true if any content was moved and false otherwise.
*/
window.moveOverflow = function(originalElement, overflowElement) {
  const overflowing = isOverflowing(originalElement)
  
  while(isOverflowing(originalElement)) {
    const elToMove = originalElement.lastElementChild;
    overflowElement.prepend(elToMove);
  }

  return overflowing
}

/*
Shows the targetElement if and _only_ if the content in the contentElement
does not match the original content. Useful for showing a prompt to save
changes if a user has edited a field from its original value.

If the value for originalContent is coming from a variable, make sure to
Javascript-escape it with the rails `j` view helper and wrap it in quotes
to identify it as a string rather than Javascript code.
*/
window.showTargetOnMismatch = function(targetElement, originalContent, contentElement) {
  if(contentElement.value == originalContent) {
    setElClasses(targetElement, ['hidden'], true)
  } else {
    setElClasses(targetElement, ['hidden'], false)
  }
}

window.hideError = function(event, fieldName) {
  const targetEl = event.target;

  // Hide the error description, if it exists for the field
  if (byId("error-for-" + fieldName)) {
    setElClasses(byId("error-for-" + fieldName), ["hidden"], true);
  }

  // Remove the error classes on the field itself 
  // and revert it to its normal appearance
  setElClasses(event.target.parentElement, ['field_with_errors'], false);
}

window.isInFocusById = function(element_id) {
  return document.activeElement === byId(element_id)
}

/*
Automatically detects inhumanly fast input that is followed by ENTER i.e a barcode scan.
Calls the handler function when a barcode scan is detected IF no blacklisted element is in focus in the document.
*/

window.BarcodeScanner = class BarcodeScanner {
  constructor(timeOut, barcodeHandler, elementIgnoreList = []) {

    this.timeOut = timeOut;
    this.barcodeHandler = barcodeHandler;
    this.lastKeyPressTimestamp = Date.now();
    this.elementIgnoreList = elementIgnoreList;
    this.resetCapture();

    document.addEventListener('keypress', this.keypress.bind(this));
  }

  didTimeout() {
    return (Date.now() - this.timeOut > this.lastKeyPressTimestamp)
  }

  processEnterKeypress(e) {
    if (this.didTimeout()){
      this.resetCapture();
      this.lastKeyPressTimestamp = Date.now();
      return;
    }

    this.dispatchScanEvent()
  }

  processOtherKeypress(e){
    if (this.didTimeout()) this.capture = '';
    this.lastKeyPressTimestamp = Date.now();
    this.capture += e.key;
  }

  keypress(e) {
    if (e.key === "Enter" || e.keyCode == 13) {
      this.processEnterKeypress(e);
    }else{
      this.processOtherKeypress(e);
    }
  }

  dispatchScanEvent(){

    if (!this.isBlacklistedElementInFocus()) this.barcodeHandler(this.capture);
    this.resetCapture();
  }

  resetCapture() {
    this.capture = '';
  }

  isBlacklistedElementInFocus() {
    for (let el_id of this.elementIgnoreList) {
      if (isInFocusById(el_id)) return true;
    }
    return false;
  }
}

window.postJSON = async function postJSON(url = "", data = {}) {

  try {
    const csrf = document.getElementsByName('csrf-token')[0].content
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrf

      },
      body: JSON.stringify(data),
    });

    return response
  } catch (error) {
    console.error("Error:", error);
  }

}

window.PetOSClient = class PetOSClient {

  static async checkRoles({roles, pin}) {
    return await postJSON('/admin/admin_roles/check_roles', { pin: pin, roles: roles })
  }

}


