// This file is automatically compiled by Webpack, along with any other files
// present in this directory. Import any per-page application logic from the
// 'src' folder and use these pack files to reference that code so it'll be
// compiled.

import Rails from "@rails/ujs";
import * as ActiveStorage from "@rails/activestorage";
import "channels";

import "stylesheets/application"; // Tailwind et al. CSS

// Libraries
import "libraries/autocomplete/auto-complete.min.js";
import "libraries/autocomplete/auto-complete.css";

// General (ie. non-page specific) reusable JS helpers
import "src/helpers";

// Per-page javascript inclusions.
// These are not _actually_ limited to a page. This is just to help organize.
import "src/layouts/admin/_layout";

// Stimulus Webpack Helpers
// https://www.stimulus-components.com/docs
// https://github.com/hotwired/stimulus-rails
import { Application } from "@hotwired/stimulus";
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers";

// Import all images into webpacker s.t. they're available in JS/CSS/HTML
require.context("../images", true);
require.context("../fonts", true);

window.Stimulus = Application.start();
const context = require.context("../controllers", true, /\.js$/);
Stimulus.load(definitionsFromContext(context));

// Configure Stimulus development experience
Stimulus.debug = false;
Stimulus.handleError = (error, message, detail) => {
  console.warn(message, detail);
  ErrorTrackingSystem.captureException(error);
};

Rails.start();
ActiveStorage.start();
