// https://www.stimulus-components.com/docs/stimulus-clipboard/
// import Clipboard from "stimulus-clipboard";
// export default Clipboard;

import { Controller } from "@hotwired/stimulus";

// This whole component was copied from their source here:
// https://github.com/stimulus-components/stimulus-clipboard/blob/master/src/index.ts
// Expanded to fix the dumb line break issues by encoding the value first.
export default class extends Controller {
  static targets = ["button", "source"];
  static values = {
    successDuration: {
      type: Number,
      default: 2000,
    },
  };

  connect() {
    if (!this.hasButtonTarget) return;

    this.originalText = this.buttonTarget.innerText;
  }

  copy(event) {
    event.preventDefault();

    // Fun Fact: Spaces and New Lines breaks the clipboard -- strict encoding and decoding
    const decodedValue = Buffer.from(
      this.sourceTarget.value,
      "base64"
    ).toString("ascii");

    navigator.clipboard.writeText(decodedValue).then(() => this.copied());
  }

  copied() {
    if (!this.hasButtonTarget) return;

    if (this.timeout) {
      clearTimeout(this.timeout);
    }

    this.buttonTarget.innerText = this.data.get("successContent");

    this.timeout = setTimeout(() => {
      this.buttonTarget.innerText = this.originalText;
    }, this.successDurationValue);
  }
}
